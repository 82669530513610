import React from 'react'
import Page from '../page/'
import Tags from '../../atoms/tags'
import Button from '../../atoms/button'
import Search from '../../molecules/search/'
import ArticlesList from '../../organisms/article-list/'

const IndexPage = props => {

  return (
    <Page>
       <div className="row p-frontpage">
       <div className="col-md-8 p-frontpage__intro">
        <h1>{props.intro.title}</h1>
        <div className="t4" dangerouslySetInnerHTML={{__html: props.intro.description}} />
        <div>
          <h3>I write about</h3>
          <Tags tags={props.tags}/>
        </div>
      </div>
      <div className="col-md-4 p-frontpage__navigation">
        <Search classNames={'p-frontpage'} cols="12" lang="en" />
        <h3>Latest</h3>
        <ArticlesList posts={props.posts}/>
        <Button title="See all" url="/articles" iconRight="fas fa-chevron-right" />
      </div>
         </div> 
    </Page>
  )
}

export default IndexPage