import React from 'react'
import { graphql } from 'gatsby'
import IndexPage from '../components/templates/frontpage/'

const AboutPage = props => {
  const posts = props.data.allMarkdownRemark
  const intro = props.data.site.siteMetadata
  const tags = []
  
  posts.edges.map(({ node }) => (
    node.frontmatter.tags.map(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag)
      }
  })))

  const pagePosts = []
  posts.edges.map(({ node }) => pagePosts.push(node))

  return (
    <IndexPage intro={intro} posts={pagePosts} tags={tags}/>
  )
}

export default AboutPage

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: {frontmatter: {publish: {ne: false}}},
      limit: 3
      ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            tags
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
